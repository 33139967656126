import { ref } from 'vue'

//重置编辑或新建表单
export const ruleFormRef = ref(null);
export const resetForm = (form) => {

    ruleFormRef.value.clearValidate();
    for (let key in form) {
        if (Array.isArray(form[key])) {
            form[key] = []
        } else {
            form[key] = "";
        }

    }
    console.log(form)
};